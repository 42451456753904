import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./UserChat.css";
import UserTable from "./UserTable";

const UserChat = ({ match }) => {
  // const url = "http://localhost:5000/userData";
  const url =
    "https://sleepmedicineassitantbackend-dot-sleepmedicine.uc.r.appspot.com/userData";
  const sessionID =
    "439d03a4-ed81-424a-addc-a486c876978d7d86668d-b314-4b67-9277-c0beb6f1e8fe";
  const [loading, setLoading] = useState(true);
  const params = new URLSearchParams(useLocation().search);
  const [messages, setMessages] = useState([]);

  useEffect(async () => {
    console.log("match", match);
    await axios
      .post(`${url}/${match.params.sessionID}`)
      .then((res) => {
        console.log(res.data.data);
        setMessages(res.data.data);
      })
      .catch((err) => err.message);
    setLoading(false);
    console.log("this is userchat page");
  }, []);
  return (
    <div className="chatBox">
      {/* <div className="chat">
				<h1 className="chatHeading">CONVERSATION</h1>
				<hr style={{ marginBottom: "20px", height: "5px", color: "red" }} />
				{loading && <h1 className="loading">Loading . . . .</h1>}
				{messages?.messagesArray?.map((message) => {
					return (
						<>
							<p className={message.speaks === "bot" ? "left" : "right"}>
								<p className="dropShadow">{message.msg.text.text}</p>
							</p>
							<p className={message.speaks === "me" && "showTime"}>
								{message.time}
                
							</p>
						</>
					);
				})}
			</div> */}
      <UserTable messages={messages} loading={loading} />

      {!loading && (
        <div className="chatBox__highlight" style={{ marginBottom: 40 }}>
          <h1>
            Please contact us to discuss this further with a Specialist Doctor.
          </h1>
        </div>
      )}
    </div>
  );
};

export default UserChat;
