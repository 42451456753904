import React, { useEffect, useState } from "react";
import "./User.css";
import { useHistory, useLocation } from "react-router";

const User = ({ user, match, id }) => {
  //   const url = "http://localhost:5000";
  const url =
    "https://sleepmedicineassitantbackend-dot-sleepmedicine.uc.r.appspot.com";
  const [loading, setLoading] = useState(false);
  const params = new URLSearchParams();
  const userSessionID = id;

  const history = useHistory();
  const [message, setMessages] = useState([]);

  const conversation = () => {
    history.push(`/userchat/${userSessionID}`);
  };

  return (
    <div style={{ marginBottom: 200 }}>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        Hello {user.userName} !
      </h2>
      <p onClick={conversation}>
        <span
          className="user"
          style={{ cursor: "pointer", textAlign: "center" }}
        >
          Click here to see your AI assisted diagnosis results
        </span>
      </p>
    </div>
  );
};

export default User;
