import styled from "styled-components";

export const FooterParent = styled.div`
  /* height: 40px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* background: #3ea7ec; */
  /* color: #fff; */
`;
