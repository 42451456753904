import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import User from "./User";

const UserSession = ({ displayID }) => {
  //   const url = "http://localhost:5000/allAdminData";
  const url =
    "https://sleepmedicineassitantbackend-dot-sleepmedicine.uc.r.appspot.com/allAdminData";
  const [session, setSession] = useState([]);
  const params = new URLSearchParams(useLocation().search);
  const id = displayID;
  console.log(displayID);
  const [message, setMessage] = useState([]);

  useEffect(() => {
    axios
      .get(url)
      .then((res) => {
        console.log(res.data);
        setSession(res.data);
      })
      .catch((err) => err.message);
  }, []);
  // console.log(params);

  return (
    <div>
      <h1 style={{ textAlign: "center", marginBottom: "20px", marginTop: 20 }}>
        User Session
      </h1>
      {session
        .filter((user) => user.userSessionID === id)
        .map((userr) => (
          <User key={userr._id} user={userr} id={id} />
        ))}
      {/* <h1>{id}</h1> */}
    </div>
  );
};

export default UserSession;
