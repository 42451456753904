import React from "react";
import "./Header.css";
import {
  LogoImage,
  TopHeader,
  LogoHeading,
  LogoMainHeading,
  LogoSubHeading,
  ExtraSpace,
} from "./TopBanner.style";
import logoImage from "./images/Logo.png";
const Header = () => {
  return (
    <TopHeader>
      <LogoImage>
        <img src={logoImage} />
      </LogoImage>
      <LogoHeading>
        <LogoMainHeading>
          <p id="heading">Sleep Disorder Diagnostic AI Assistant</p>
        </LogoMainHeading>
        <LogoSubHeading>
          <strong>
            <p>Somnology Tech LLC</p>
          </strong>
        </LogoSubHeading>
      </LogoHeading>
    </TopHeader>
  );
};

export default Header;
