import React, { useEffect, useState } from "react";
import "./App.css";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./Header";
import UserSession from "./UserSession";
import UserChat from "./UserChat";
import Footer from "./components/Footer";

function App() {
  const [id, setID] = useState("");
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params.get("userId"));
    setID(params.get("userId"));
  }, []);

  return (
    <div className="App">
      <Header />
      <Router>
        <Switch>
          <Route exact path="/userchat/:sessionID" component={UserChat}></Route>
          <Route exact path="/">
            <UserSession displayID={id} />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
