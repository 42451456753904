import React from "react";
import "./UserTable.css";

const ChatTable = ({ messages, loading }) => {
  // const [loading, setLoading] = useState(true);
  return (
    <div className="chatTable">
      <h1 className="chattableheading">Potential Diagnosis Results </h1>
      <hr style={{ marginBottom: "20px" }} />
      <div className="chatTable__data">
        {/* {!loading && (
          <div className="tableHeading">
            <div className="heading">
              <h3>Root</h3>
            </div>
            <div className="heading">
              <h3>Symptom</h3>
            </div>
            <div className="heading">
              <h3>Potential Diagnosis</h3>
            </div>
          </div>
        )} */}
        <table className="user-data-table">
          <thead>
            <tr className="theadrow">
              <td>Problem</td>
              <td>Symptom</td>
              <td>Potential Diagnosis</td>
            </tr>
          </thead>
          <tbody>
            {messages?.diagnosisArray?.map((message) => {
              return (
                <>
                  {console.log(message)}
                  {/* <table> */}

                  <tr className="tableData">
                    <td className="capitalise">
                      {console.log("message root", message.root)}
                      {message.root.toLowerCase().includes("parasomnia")
                        ? "Unusual Behavior During Sleep"
                        : message.root}
                    </td>
                    <td className="capitalise">{message.basedOn}</td>
                    <td className="capitalise">{message.diagnosisValue}</td>
                  </tr>
                  {/* </tbody> */}
                  {/* </table> */}
                  {/* </div> */}
                </>
              );
            })}
          </tbody>
          {loading && <h1 className="loading">Loading . . . . </h1>}
        </table>
      </div>
    </div>
  );
};

export default ChatTable;
